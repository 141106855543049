import { render, staticRenderFns } from "./fankui.vue?vue&type=template&id=779c8410&scoped=true"
import script from "./fankui.vue?vue&type=script&lang=js"
export * from "./fankui.vue?vue&type=script&lang=js"
import style0 from "./fankui.vue?vue&type=style&index=0&id=779c8410&prod&lang=scss&scoped=true"
import style1 from "./fankui.vue?vue&type=style&index=1&id=779c8410&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779c8410",
  null
  
)

export default component.exports