<template>
    <div>
        <div class="topimage">
            <el-image  style="width:100%" :src="require('@/assets/jishu/fankui_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <el-form ref="form" :class="$i18n.locale == 'en' ? '' : 'forms'" :model="form" :rules="rules" :label-width="$i18n.locale == 'en' ? '120px' :'90px'" style="margin-top:30px">
                <!-- <el-form-item :label="$t('fankui.productModel')" prop="product">
                    <el-input v-model="form.product"></el-input>
                </el-form-item> -->
                <el-form-item :label="$t('fankui.type')" prop="leixing">
                    <el-select v-model="form.leixing"  style="width:100%">
                        <el-option :label="$t('fankui.function')" value="1"></el-option>
                        <el-option :label="$t('fankui.facade')" value="2"></el-option>
                        <el-option :label="$t('fankui.software')" value="3"></el-option>
                        <el-option :label="$t('fankui.hardware')" value="4"></el-option>
                        <el-option :label="$t('fankui.else')" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item :label="$t('fankui.describe')" prop="wenti">
                    <el-input type="textarea"  :autosize="{ minRows: 2, maxRows: 4}" v-model="form.wenti"></el-input>
                </el-form-item> -->
                <el-form-item :label="$t('fankui.improvementSuggestions')" prop="jianyi">
                    <el-input type="textarea"  :autosize="{ minRows: 5, maxRows: 10}" v-model="form.jianyi"></el-input>
                </el-form-item>
                <el-form-item :label="$t('reg.name')" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('reg.mobilePhone')" prop="shouji">
                    <el-input v-model="form.shouji"></el-input>
                </el-form-item>
                <el-form-item :label="$t('reg.mailbox')" prop="youxiang">
                    <el-input v-model="form.youxiang"></el-input>
                </el-form-item>
                <el-form-item :label="$t('reg.companyName')" prop="company">
                    <el-input v-model="form.company"></el-input>
                </el-form-item>
                <el-form-item :label="$t('reg.address')" prop="address">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="tijiao"  @click="onSubmit">{{$t('fankui.submit')}}</div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { Message } from "element-ui";
import { productscollectAdd } from "@/api/api"; 
export default {
      data() {
        var checkEmail = (rule, value, callback) => {
        const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!value) {
            return callback(new Error(this.$t('login.notnull')))
        }
        setTimeout(() => {
            if (mailReg.test(value)) {
                callback()
            } else {
                callback(new Error(this.$t('reg.emtail')))
            }
        }, 100)
      }
      return {
        form: {},
        rules: {
            leixing:[
            { required: true, message: this.$t('fankui.typeMsg'), trigger: "blur" },
            ],
            jianyi:[
            { required: true, message: this.$t('fankui.improvementSuggestionsmsg'), trigger: "blur" }
            ],
            name:[
            { required: true, message: this.$t('login.notnull'), trigger: "blur" }
            ],
            youxiang:[
            {  required: true,validator: checkEmail, trigger: 'blur' }
            ]
        },
        title:''
      }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.Productfeedback')+'-'+this.$t('seo.title')
    },
    methods: {
      onSubmit() {
        this.$refs["form"].validate(valid => {
            if (valid) {
                productscollectAdd(this.form).then(response=>{
                    Message({
                        message:'提交成功',
                        type:'success'
                    })
                    this.$router.go(-1);
                })
            }
        });
      }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 30%;
        margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
        .tijiao{
            width: 250px !important;
        }
    }
    .tijiao{
        width: 350px;
        height: 48px;
        background: #0052D9;
        text-align: center;
        line-height: 48px;
        color: #fff;
        margin-top: 3%;
    }
</style>
<style  scoped>
    .forms>>>.el-form-item label:after {
        content: " ";
        display: inline-block;
        width: 100%;
    }
 
    .forms>>>.el-form-item__label {
        text-align: justify;
        height: 20px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
    }
    /* 这里去除必选字段的*,这个符号会造成一定影响,去掉之后我用了li列表进行定位,在前面加上" * ". */
    .forms>>>.el-form-item.is-required .el-form-item__label:before {
        content: none !important;
    }
</style>